import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import Layout from "../components/layout"
import Seo from "../components/seo"

const StrategyPage = ({ data }) => {
  const strategyPage = data.allContentfulStrategyPage.nodes[0]
  const strategies = data.allContentfulStrategy.nodes
  const strategyHelps = data.allContentfulStrategyHelp.nodes

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <b className="font-bold">{text}</b>,
      [MARKS.ITALIC]: text => <i className="italic" >{text}</i>,
      [MARKS.UNDERLINE]: text => <u className="underline" >{text}</u>
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        return (
          <a href={uri} className="underline">
            {children}
          </a>
        )
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2>{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3>{children}</h3>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="whitespace-pre-line">
          {children}
        </p>
      ),
    },
  }

  const listOptions = {
    ...options, 
    renderNode: {
      ...options.renderNode,
      [BLOCKS.UL_LIST]: (node, children) => {
        return <ul className="strategy-list font-serif lg:pr-20">{children}</ul>
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return <li className="py-3 px-3 list-outside">{children}</li>
      },
    }
  }

  return (
    <Layout>
      <Seo title="Strategy" />
      <div className="container mx-auto pb-32">
        <div className="bg-white p-5 lg:py-20">
          <div className="container p-5 relative">
            <h1 data-sal="slide-left" data-sal-duration="1000" className="text-purple">
              {strategyPage.subtitle}
            </h1>
            <h2 data-sal="slide-left" data-sal-duration="1000" data-sal-delay="200" className="text-black text-left py-20 block text-4xl font-semibold lg:text-6xl">
              {strategyPage.title}
            </h2>

            <div className="flex flex-col lg:flex-row text-lg py-10 mx-auto justify-center lg:px-20">
              {strategies?.map((strategy, index) => {
                const icon = getImage(strategy.icon)

                return (
                  <div key={strategy.id} data-sal="slide-left" data-sal-duration="1000" data-sal-delay={index*100} className="lg:w-1/3">
                    <GatsbyImage image={icon} alt={strategy.name} />
                    <h3 className="text-purple px-3 py-10">{strategy.name}</h3>
                    {renderRichText(strategy.description, listOptions)}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray">
        <div className="container mx-auto p-20">
          <h4 className="pt-20 text-2xl lg:text-4xl">How you can help</h4>
          <div className="py-10 pb-32">
            <StaticImage
              src="../images/down-arrow.png"
              width={50}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Arrow"
            />
          </div>

          <div className="flex flex-col divide-y divide-peach">
            {strategyHelps?.map(help => (
               <div key={help.id} className="flex flex-col lg:flex-row py-10 lg:py-20">
                <h6 className="text-purple lg:w-1/2 lg:pr-40 pb-10 lg:pb-0">
                  {help.title}
                </h6>
                <div className="lg:w-1/2 font-serif lg:pr-20">
                 {renderRichText(help.description, options)}
                </div>
             </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default StrategyPage

export const pageQuery = graphql`
  query StrategyQuery {
    allContentfulStrategyPage {
      nodes {
        title
        subtitle
      }
    }
    allContentfulStrategy(sort: {fields: order}) {
      nodes {
        id
        name
        description {
          raw
        }
        icon {
          gatsbyImageData(width: 150, quality: 95)
        }
      }
    }
    allContentfulStrategyHelp {
      nodes {
        id
        title
        description {
          raw
        }
      }
    }
  }
`